<template>
	<div>
		<span v-if="hasThirdLevel">
			<span
				class="ml-2 pointer text-primary underline"
				@click="openCategory(categoriesInfo.parent.parent_id)">...</span>
			<span class="mx-1"> / </span>
		</span>
		<span v-if="hasSecondLevel">
			<span
				class="pointer text-primary underline"
				@click="openCategory(categoriesInfo.parent.id)">{{ categoriesInfo.parent.name }}</span>
			<span class="mx-1"> / </span>
		</span>
		<strong
			v-if="hasFirstLevel"
			class="ml-2">
			{{ categoriesInfo.current.name }}
		</strong>
	</div>
</template>

<script>
import { VideoCategories } from '@/translations';

export default {
	name: 'CategoriesNavigator',
	messages: [VideoCategories],
	props: {
		categoriesInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		hasFirstLevel() {
			return typeof this.categoriesInfo.current !== 'undefined';
		},
		hasSecondLevel() {
			return typeof this.categoriesInfo.parent !== 'undefined';
		},
		hasThirdLevel() {
			if (this.hasSecondLevel) {
				return this.categoriesInfo.parent.parent_id;
			}
			return false;
		},
	},
	methods: {
		openCategory(id) {
			this.$emit('openCategory', id);
		},
	},
};
</script>
<style scoped>
.underline:hover {
	text-decoration: underline;
}
</style>
