<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="text-center">
		<div class="position-relative">
			<div
				:class="blocked ? 'gray-overlay' : 'black-overlay'"
				class="w-100 h-100 position-absolute">
				<b-button
					v-if="!blocked"
					v-b-modal="`modal-${id}`"
					variant="link"
					class="h-100">
					<img
						:class="!isSmallSize ? 'w-50 pointer play-icon' : 'w-10 pointer play-icon-smaller'"
						:src="require(`@/assets/images/common/playIcon.svg`)"
						fluid
						alt="Play Video">
				</b-button>
			</div>
			<b-img
				:src="thumbnail"
				fluid
				alt="Play Video"
				class="w-100"
				@error="thumbnail = defaultThumbnail" />
		</div>
		<div
			v-if="!hideMetadata"
			class="pt-3 px-4 pb-4">
			<p
				:class="`text-${ titleVariant || 'dark' }`"
				class="h4 font-weight-bold mb-1">
				{{ title }}
			</p>
			<p
				:class="`text-${ authorVariant || 'primary' }`"
				class="h5 mb-1">
				{{ author }}
			</p>
			<p
				v-if="!!expiration"
				class="text-medium text-dark pt-1 border-top">
				<i class="fa fa-clock align-icon" /> <span v-html="expiration" />
			</p>
		</div>
		<b-modal
			v-if="!blocked"
			:id="`modal-${id}`"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="videoUrl"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
	</div>
</template>
<script>
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'VideoPreview',
	props: {
		id: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		titleVariant: {
			type: String,
			default: '',
		},
		author: {
			type: String,
			default: '',
		},
		authorVariant: {
			type: String,
			default: '',
		},
		expiration: {
			type: String,
			default: '',
		},
		blocked: {
			type: Boolean,
			default: false,
		},
		hideMetadata: {
			type: Boolean,
			default: false,
		},
		thumbnailSrc: {
			type: String,
			required: true,
		},
		thumbnailDefaultSrc: {
			type: String,
			default: '',
		},
		videoUrl: {
			type: String,
			required: true,
		},
		isSmallSize: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			theme: process.env.VUE_APP_THEME,
			thumbnail: '',
			defaultThumbnail: '',
		};
	},
	watch: {
		thumbnailSrc(newVal) {
			this.thumbnail = newVal;
		},
		thumbnailDefaultSrc(newVal) {
			this.defaultThumbnail = newVal;
		},
	},
	mounted() {
		this.thumbnail = this.thumbnailSrc;
		this.defaultThumbnail = this.thumbnailDefaultSrc || `${S3_PATH}/assets/videos/${this.theme}/video-preview-default.jpg`;
	},
};
</script>
<style scoped>
	.black-overlay {
		background: rgba(23, 23, 25, 0.5);
	}
	.gray-overlay {
		background: rgba(116, 116, 116, 0.5);
	}
	.play-icon {
		min-width: 75px;
		transition: all .3s ease-in-out;
	}
	.play-icon-smaller {
		min-width: 50px;
		transition: all .3s ease-in-out;
	}
	.play-icon:hover {
		transform: scale(1.1);
	}
</style>
