import {
	GET_HIGHLIGHT_VIDEO,
	GET_HIGHLIGHT_VIDEOS,
	GET_PUSH_NOTIFICATIONS_HIGHLIGHT_VIDEOS,
	GET_HIGHLIGHT_VIDEO_CATEGORIES,
	CREATE_HIGHLIGHT_VIDEO,
	UPDATE_HIGHLIGHT_VIDEO,
	REMOVE_HIGHLIGHT_VIDEO,
	SORT_HIGHLIGHT_VIDEO,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

const axios = new Req();
class HighlightVideo {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	getHighlightVideo(highlightVideoId) {
		const { method, endpoint } = GET_HIGHLIGHT_VIDEO;
		return this.data[method](endpoint(highlightVideoId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getHighlightVideos(options) {
		const { endpoint, method } = GET_HIGHLIGHT_VIDEOS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPushHighlightVideos(queryParams) {
		const { endpoint, method } = GET_PUSH_NOTIFICATIONS_HIGHLIGHT_VIDEOS;
		const params = Req.setFilters(queryParams);
		return this.data[method](endpoint(params)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCategories() {
		const { endpoint, method } = GET_HIGHLIGHT_VIDEO_CATEGORIES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createHighlightVideo(options) {
		const { endpoint, method } = CREATE_HIGHLIGHT_VIDEO;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			this.pagination = axios.pagination;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	updateHighlightVideo(highlightVideoId, options) {
		const { method } = UPDATE_HIGHLIGHT_VIDEO;
		const endpoint = UPDATE_HIGHLIGHT_VIDEO.endpoint(highlightVideoId);
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	deleteHighlightVideo(id, payload) {
		const { method, endpoint } = REMOVE_HIGHLIGHT_VIDEO;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	sortHighlightVideo(id, options) {
		const { method, endpoint } = SORT_HIGHLIGHT_VIDEO;
		return this.data[method](endpoint(id), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default HighlightVideo;
