<template>
	<div>
		<b-card
			no-body
			class="overflow-hidden pointer card-hover"
			@click="openCategory(categoryId)">
			<div class="row no-gutters">
				<div
					class="col-4 card-thumbnail-containter"
					:style="{'background-image': `url(${thumbnail})`}" />
				<div class="col-8">
					<b-card-body class="card-text-container text-center">
						<span>{{ categoryTitle }}</span>
					</b-card-body>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	name: 'VideoCategoryCard',
	props: {
		categoryTitle: {
			type: String,
			default: '',
		},
		thumbnail: {
			type: String,
			default: '',
		},
		categoryId: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		openCategory(id) {
			this.$emit('openCategory', id);
		},
	},
};
</script>
<style scoped>
.card-hover:hover {
	background-color: rgba(25, 25, 25, 0.2);
}
.card-hover {
	max-height: 70px;
	height: 70px;
}
.card-thumbnail-containter {
	max-width: 185px;
	height: 70px;
	background-size: cover;
	background-position: center;
}
.card-text-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0.5rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
}
</style>
