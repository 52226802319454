<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			v-if="['xs', 'sm'].includes(windowWidth) && inCategory && !loading"
			class="row mb-3">
			<div
				class="ml-2"
				style="display: inline-block">
				<categories-navigator
					v-if="!isFiltering"
					:categories-info="directoryInfo"
					@openCategory="openCategory" />
			</div>
		</div>
		<div>
			<b-row class="mb-3">
				<b-col
					cols="12"
					class="d-flex align-items-center">
					<b-button
						:variant="!showFilters ? 'primary' : 'secondary'"
						class="btn bg-primary-alt mr-3"
						@click="showFilters = !showFilters">
						<i
							class="fa fa-filter"
							aria-hidden="true" />
					</b-button>
					<b-button
						v-if="inCategory && !isFiltering"
						variant="primary"
						:title="translate('go_back')"
						@click="openCategory(previousCategory)">
						<span class="fa fa-arrow-left" />
					</b-button>
					<div
						v-if="!['xs', 'sm'].includes(windowWidth) && inCategory"
						class="ml-4"
						style="display: inline-block">
						<categories-navigator
							v-if="!isFiltering"
							:categories-info="directoryInfo"
							@openCategory="openCategory" />
					</div>
					<b-form-select
						id="highlight-language"
						v-model="selectedLanguage"
						type="text"
						class="form-control ml-auto mr-4"
						:style="['xs', 'sm'].includes(windowWidth) ? 'width:100px' : 'width:250px'"
						@change="changeLanguage($event)">
						<template slot="first">
							<option
								v-for="language in availableLanguages"
								:key="language.text"
								:value="language.value">
								{{ translate(language.text.toLowerCase()) }}
							</option>
						</template>
					</b-form-select>
					<b-button
						v-if="$can('highlight_videos', 'create')"
						class="border-0"
						variant="primary"
						@click="addNewVideo()">
						{{ translate('add') }}
					</b-button>
				</b-col>
			</b-row>
			<data-filter
				:display="showFilters"
				get-by="name"
				@submit="getDataFiltered"
				@clear="clearFilters">
				<template slot="form">
					<div class="row">
						<div class="col-sm">
							<div
								class="form-group">
								<label
									for="title"
									class="label">{{ translate('title_tag') }}</label>
								<input
									id="title"
									v-model="filters.title"
									name="title"
									type="text"
									class="form-control"
									style="height: 43px;"
									:placeholder="translate('no_options_placeholder_search')">
							</div>
						</div>
						<div class="col-sm">
							<b-form-group id="formSelector">
								<slot name="countries">
									<label>
										{{ translate('country') }}
									</label>
								</slot>
								<multiselect
									id="countries"
									v-model="selectedCountries"
									:options="countries"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:preselect-first="false"
									:placeholder="translate('country_placeholder')"
									label="text"
									:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
									track-by="value"
									name="countries">
									<template slot="selection" />
									<span slot="noResult">{{ translate('data_not_found') }}</span>
									<template #option="{option}">
										{{ option.text }}
									</template>
								</multiselect>
							</b-form-group>
						</div>
					</div>
				</template>
			</data-filter>
		</div>
		<template v-if="!loading">
			<hr>
			<template v-if="hasCategories">
				<div class="row px-3">
					<div
						v-for="(category, index) in categories"
						:key="index"
						class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-2">
						<video-category-card
							:thumbnail="category.attributes.images[selectedLanguage]"
							:category-id="Number(category.id)"
							:category-title="category.attributes.title"
							@openCategory="openCategory" />
					</div>
				</div>
				<hr v-if="hasVideos">
			</template>
			<template v-if="showVideos">
				<div
					v-for="(highlightVideoInfo, index) in videos"
					:key="highlightVideoInfo.id">
					<div class="row no-gutters">
						<div class="col">
							<highlight-video-item
								:highlight-video-data="highlightVideoInfo"
								:show-move-up="showSortButton(highlightVideoInfo, 'up')"
								:show-move-down="showSortButton(highlightVideoInfo, 'down')"
								@update="updateHighlightVideo"
								@delete="deleteHighlightVideo"
								@sort="sortHighlightVideo" />
						</div>
					</div>
					<hr v-if="index !== videos.length - 1">
				</div>
			</template>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasCategories || hasVideos" />
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {
	HighlightVideos as HighlightVideoMessages,
	Countries,
	Announcements,
	VideoCategories,
	Time,
	Languages,
	Tags,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import { LANGUAGES } from '@/settings/HighlightVideos';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import DataFilter from '@/components/DataFilter';
import HighlightVideo from '@/util/HighlightVideo';
import WindowSizes from '@/mixins/WindowSizes';
import HighlightVideoItem from './components/HighlightVideoItem';
import VideoCategoryCard from './components/VideoCategoryCard';
import CategoriesNavigator from './components/CategoriesNavigator';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'HighlightVideos',
	messages: [HighlightVideoMessages, Countries, Announcements, Time, VideoCategories, Languages, Tags],
	components: {
		HighlightVideoItem,
		VideoCategoryCard,
		CategoriesNavigator,
		DataFilter,
		Multiselect,
	},
	mixins: [WindowSizes, FiltersParams],
	data() {
		return {
			highlightVideos: new HighlightVideo(),
			removeHighlightVideo: new HighlightVideo(),
			sortVideo: new HighlightVideo(),
			alert: new this.$Alert(),
			title: '',
			countries: [],
			validCountries,
			selectedCountries: [],
			countriesOnly: [],
			availableLanguages: [],
		};
	},
	computed: {
		selectedLanguage: {
			get() {
				try {
					const { language } = this.highlightVideos.data.response.data.data.meta;
					return language;
				} catch (error) {
					return '';
				}
			},
			set(language) {
				this.$route.query.language = language;
			},
		},
		loading() {
			return !!this.highlightVideos.data.loading;
		},
		directoryInfo() {
			try {
				const { directory_info: directoryInfo } = this.highlightVideos.data.response.data.data;
				return directoryInfo;
			} catch (error) {
				return {};
			}
		},
		videos() {
			try {
				const { videos } = this.highlightVideos.data.response.data.data;
				return videos;
			} catch (error) {
				return [];
			}
		},
		categories() {
			try {
				const { categories } = this.highlightVideos.data.response.data.data;
				return categories;
			} catch (error) {
				return [];
			}
		},
		hasVideos() {
			const response = this.videos.length;
			return !!response;
		},
		hasCategories() {
			const response = this.categories.length;
			return !!response;
		},
		inCategory() {
			const { query } = this.$route;
			return typeof query.categoryId !== 'undefined';
		},
		isFiltering() {
			const { query } = this.$route;
			return !!(query.title || query.countries);
		},
		showVideos() {
			if (this.inCategory) {
				return this.hasVideos;
			}
			return true;
		},
		previousCategory() {
			try {
				const { parent } = this.directoryInfo;
				return parent.id;
			} catch (error) {
				return null;
			}
		},
		currentCategory() {
			try {
				const { current } = this.directoryInfo;
				return current.code_name;
			} catch (error) {
				return '';
			}
		},
	},
	watch: {
		selectedCountries: function countriesWatch() {
			const countriesSelect = this.selectedCountries;
			if (this.handleSelectAll(countriesSelect) === true && countriesSelect !== this.countriesOnly) {
				this.selectedCountries = this.countriesOnly;
			}
		},
	},
	created() {
		this.noFilterable.push('categoryId');
		this.noFilterable.push('language');
	},
	mounted() {
		this.getLanguages();
		this.initializeMultiselect(this.filters.countries);
		this.highlightVideos.getHighlightVideos();
	},
	methods: {
		getData() {
			const { query } = this.$route;
			const options = { ...query };

			this.highlightVideos.getHighlightVideos(options);
		},
		getLanguages() {
			this.availableLanguages = LANGUAGES.map((languageCode) => ({
				value: languageCode,
				text: `${languageCode}_language`,
			}));
		},
		changeLanguage(language) {
			this.selectedLanguage = language;
			this.highlightVideos.getHighlightVideos();
		},
		getDataFiltered() {
			this.addCountries();
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (options.countries && this.selectedCountries.length === 0) {
				const params = { ...options, countries: '' };
				this.highlightVideos.getHighlightVideos(params);
				return;
			}
			this.highlightVideos.getHighlightVideos(options);
		},
		addCountries() {
			if (this.selectedCountries.length > 0) {
				this.filters.countries = [];
				this.selectedCountries.forEach((country) => {
					this.filters.countries.push(country.value);
				});
			}
		},
		initializeMultiselect(selectValue) {
			this.countries = this.validCountries.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.countriesOnly = [...this.countries];
			this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			this.selectedCountries = this.searchSelectValues(selectValue ? selectValue.split(',') : [], this.countriesOnly);
		},
		searchSelectValues(selectValue, searchIn) {
			let values = [];
			let item;
			let selection = selectValue;

			if (typeof selectValue === 'undefined') {
				return '';
			}

			if (typeof selectValue === 'string') {
				selection = [selectValue];
			}

			if (typeof selectValue[0] !== 'string') {
				return selectValue;
			}
			const arrValues = selection;
			arrValues.forEach((value) => {
				item = searchIn.find((option) => option.value === value);
				if (typeof item !== 'undefined') values.push(item);
			});

			if (values.length === 0) {
				values = [];
			}

			return values;
		},
		handleSelectAll(select) {
			let allSelected = false;
			if (typeof select[0] === 'undefined') return false;

			const selectedCountries = select.map((country) => country.value);
			const numberOfCountries = this.countriesOnly.length;
			const selectAllExists = (this.countries[0].value === 'ALL');

			if (selectedCountries.includes('ALL') || selectedCountries.length === numberOfCountries) {
				if (selectAllExists) {
					this.countries.shift();
				}
				allSelected = true;
			}
			if ((!selectAllExists) && (selectedCountries.length !== numberOfCountries)) {
				this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
			}
			return allSelected;
		},
		showSortButton(highlightVideo, option) {
			// If there is only one video or the videos are filtered, the up and down buttons are not shown.
			if (this.videos.length === 1 || this.isFiltering) {
				return false;
			}
			// If the video is the first item the up button is not shown
			if (option === 'up' && this.videos[0].id === highlightVideo.id) {
				return false;
			}
			// If the video is the last item the down button is not shown
			if (option === 'down' && this.videos[this.videos.length - 1].id === highlightVideo.id) {
				return false;
			}
			// The videos in the middle have both buttons
			return true;
		},
		sortHighlightVideo(id, sort) {
			const { attributes } = this.videos.find((video) => video.id === id);
			const options = {
				sort_order: (sort === 'up') ? attributes.sort_order - 1 : attributes.sort_order + 1,
			};
			this.sortVideo.sortHighlightVideo(id, options).then(() => {
				this.alert.toast('success', this.translate('highlight_video_updated'));
				this.highlightVideos.getHighlightVideos();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		deleteHighlightVideo(id) {
			const videoTitle = this.videos.find((video) => video.id === id).attributes.title;
			const trans = {
				title: this.translate('delete_highlight_video_title', { title: videoTitle }),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.removeHighlightVideo.deleteHighlightVideo(id, payload).then(() => {
					this.alert.toast('success', this.translate('highlight_video_updated'));
					this.highlightVideos.getHighlightVideos();
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.removeHighlightVideo.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.removeHighlightVideo.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		updateHighlightVideo(highlightVideoId) {
			this.$router.push({ name: 'UpdateHighlightVideo', params: { highlightVideoId } });
		},
		openCategory(categoryId) {
			const options = {
				name: this.$route.name,
			};
			if (typeof categoryId !== 'undefined' && categoryId !== null) {
				options.query = { categoryId };
			}
			options.query = { ...options.query, language: this.selectedLanguage };
			this.$router.push(options).catch(() => {});
		},
		addNewVideo() {
			const options = {
				name: 'NewHighlightVideo',
			};

			if (this.inCategory) {
				const { current } = this.directoryInfo;
				options.query = { categoryId: current.id };
			}
			this.$router.push(options);
		},
	},
};
</script>
