// export const USER_TYPES = ['distributor', 'customer', 'affiliate'];
// export const DEVICE_TYPES = ['backoffice', 'mobile'];

export const LANGUAGES = ['en', 'es', 'ja', 'pt'];

export const IMAGES_EXT = ['jpg', 'png', 'jpeg'];

export const BASE_VIDEO_URL = 'https://player.vimeo.com/video/';

export const MAX_COUNTRIES = 10;
export const MAX_TAGS = 6;

export default {};
