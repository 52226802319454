<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row no-gutters px-3">
		<div class="col-12">
			<div class="row no-gutters d-flex justify-content-between">
				<div
					:class="windowWidth === 'xs' ? 'mb-2 pr-2' : 'pr-3'"
					class="col-10 col-sm-3 col-lg-2">
					<video-preview
						:id="highlightVideoData.id"
						:thumbnail-src="highlightVideoData.attributes.image"
						:video-url="videoUrl"
						:is-small-size="true"
						hide-metadata />
				</div>
				<div
					v-if="$can('highlight_videos', 'update') || $can('highlight_videos', 'delete')"
					class="col-2 col-sm-1 d-flex align-items-end flex-column order-2 order-sm-3">
					<div class="order-1 p-2">
						<div>
							<b-button
								:id="`popover-${highlightVideoData.id}`"
								class="bg-primary-alt icon-button"
								variant="primary">
								<i
									class="fa fa-ellipsis-v" />
							</b-button>
						</div>
					</div>
					<div
						v-if="$can('highlight_videos', 'update')"
						class="d-flex justify-content-around flex-column order-2 mt-auto p-2"
						:style="{ height: '90px'}">
						<div>
							<div
								v-if="showMoveUp">
								<b-button
									v-b-tooltip.hover.left
									class="icon-button"
									variant="primary"
									:title="translate('up_highlight_video')"
									@click="$emit('sort', highlightVideoData.id, 'up')">
									<i
										class="fa fa-sort-up" />
								</b-button>
							</div>
						</div>
						<div>
							<div
								v-if="showMoveDown">
								<b-button
									v-b-tooltip.hover.left
									class="icon-button"
									variant="primary"
									:title="translate('down_highlight_video')"
									@click="$emit('sort', highlightVideoData.id, 'down')">
									<i
										class="fa fa-sort-down" />
								</b-button>
							</div>
						</div>
					</div>
					<b-popover
						ref="popover"
						:target="`popover-${highlightVideoData.id}`"
						triggers="focus"
						placement="auto"
						class="z-0">
						<div class="popover-content">
							<div
								v-if="$can('highlight_videos', 'update')"
								class="popover-row d-flex text-left mb-2">
								<b-button
									variant="popover-option"
									class="p-0 d-flex align-items-center w-100"
									@click="$emit('update', highlightVideoData.id)">
									<img
										:src="require(`@/assets/images/common/library/edit.svg`)"
										class="mr-2 w-20">
									<span>{{ translate('edit') }}</span>
								</b-button>
							</div>
							<div
								v-if="$can('highlight_videos', 'delete')"
								class="popover-row d-flex text-left">
								<b-button
									variant="popover-option"
									class="p-0 d-flex align-items-center w-100"
									@click="$emit('delete', highlightVideoData.id)">
									<img
										:src="require(`@/assets/images/common/library/trashcan.svg`)"
										class="dark-filter mr-2 w-17">
									<span>{{ translate('delete') }}</span>
								</b-button>
							</div>
						</div>
					</b-popover>
				</div>
				<div class="col-12 col-sm col-lg order-3 order-sm-2">
					<div class="row no-gutters mb-2">
						<div class="col align-self-center">
							<h5 class="font-weight-heavy my-auto">
								{{ highlightVideoData.attributes.title }}
							</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-12 h6">
							<div v-if="highlightVideoData.attributes.end_date">
								<i class="fa fa-clock align-icon icon-width" /> {{ translate('from') }} <span class="font-weight-normal font-italic">{{ $moment(highlightVideoData.attributes.start_date.date).format('LLL') }}</span> {{ translate('to') }} <span class="font-weight-normal font-italic">{{ $moment(highlightVideoData.attributes.end_date.date).format('LLL') }}</span>
							</div>
							<div v-if="!highlightVideoData.attributes.end_date">
								<i class="fa fa-clock align-icon icon-width" /> <span class="font-weight-bold"> {{ translate('starts_at') }}:</span> <span class="font-weight-normal font-italic">{{ $moment(highlightVideoData.attributes.start_date.date).format('LLL') }}</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 h6">
							<i class="fa fa-map align-icon icon-width" />
							<span
								v-if="highlightVideoData.attributes.countries != ''"
								:id="'tooltip' + highlightVideoData.id">
								<span
									v-for="(country, key) in formatList(highlightVideoData.attributes.countries, maxCountries, true)"
									:key="key"
									class="pointer text-primary">
									{{ translate(country.toLowerCase()) }}<span v-if="key !== formatList(highlightVideoData.attributes.countries, maxCountries, true).length-1">,</span><span v-else-if="formatList(highlightVideoData.attributes.countries, maxCountries).length > maxCountries">...</span>
								</span>
								<b-tooltip
									:target="`tooltip${highlightVideoData.id}`"
									placement="right"
									triggers="hover">
									<span
										v-for="(country, key) in formatList(highlightVideoData.attributes.countries, maxCountries)"
										:key="key">
										{{ translate(country.toLowerCase()) }}<span v-if="key !== formatList(highlightVideoData.attributes.countries, maxCountries).length-1">,</span>
									</span>
								</b-tooltip>
							</span>
							<span
								v-else
								class="text-primary"> {{ translate('all_countries_apply') }} </span>
						</div>
					</div>
					<div
						v-if="highlightVideoData.attributes.tags != ''"
						class="row">
						<div class="col-12 h6">
							<i class="fa fa-tags align-icon icon-width" />
							<span
								:id="`tag_tooltip${highlightVideoData.id}`">
								<span class="pointer text-primary">
									{{ formatList(highlightVideoData.attributes.tags, maxTags, true).join(', ') }}<span v-if="formatList(highlightVideoData.attributes.tags, maxTags).length > maxTags">...</span>
								</span>
								<b-tooltip
									:target="'tag_tooltip' + highlightVideoData.id"
									placement="right"
									triggers="hover">
									{{ formatList(highlightVideoData.attributes.tags, maxTags, true).join(', ') }}
								</b-tooltip>
							</span>
						</div>
					</div>
					<div
						v-if="restrictedByEvents()"
						class="row">
						<div class="col-12 h6">
							<i class="fa fa-ticket-alt align-icon icon-width" /> <span class="font-weight-normal">{{ showEvents(highlightVideoData.attributes.event_code_names) }}</span>
						</div>
					</div>
					<div
						v-if="highlightVideoData.attributes.shareable"
						class="row">
						<div class="col-12 h6">
							<div>
								<i class="fas fa-share-square align-icon icon-width" /> <span class="font-weight-normal">{{ translate('shareable') }}</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 h6">
							<div>
								<i class="fas fa-globe-americas align-icon icon-width" /> <span class="font-weight-normal">{{ translate(`${highlightVideoData.attributes.language_code}_language`) }}</span>
							</div>
						</div>
					</div>
					<!-- <div class="row">
						<div class="col-12 h6">
							<i class="fa fa-globe align-icon icon-width" /> <span class="font-weight-normal">{{ translate(highlightVideoData.attributes.language_code) }}</span>
						</div>
					</div> -->
					<!-- <div class="row">
						<div class="col-12 h6">
							<i class="fa fa-desktop align-icon icon-width" /> <span class="font-weight-normal">{{ devicesText(highlightVideoData.attributes.device_type) }}</span>
						</div>
					</div> -->
					<!-- <div class="row">
						<div class="col-12 h6">
							<i class="fa fa-user align-icon icon-width" /> <span>{{ userTypesText(highlightVideoData.attributes.user_types) }}</span>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import {
	MAX_COUNTRIES,
	MAX_TAGS,
} from '@/settings/HighlightVideos';
import VideoPreview from '@/components/VideoPreview';
import WindowSizes from '@/mixins/WindowSizes';
import {
	HighlightVideos as HighlightVideoMessages,
	VideoCategories as VideoCategoriesTranslations,
	Countries,
	Events,
	Languages,
	// Users,
	Time,
} from '@/translations';

export default {
	name: 'HighlightVideoItem',
	messages: [HighlightVideoMessages, Countries, Time, VideoCategoriesTranslations, Events, Languages],
	components: {
		VideoPreview,
	},
	mixins: [WindowSizes],
	props: {
		highlightVideoData: {
			type: Object,
			required: true,
		},
		showMoveUp: {
			type: Boolean,
			required: true,
		},
		showMoveDown: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			maxCountries: MAX_COUNTRIES,
			maxTags: MAX_TAGS,
		};
	},
	computed: {
		videoUrl() {
			return `${this.highlightVideoData.attributes.url}?autoplay=1`;
		},
	},
	methods: {
		formatList(list, maxElements, hasLimit = false) {
			try {
				const elements = list.split(',');
				if (hasLimit && elements.length > maxElements) {
					return elements.slice(0, maxElements);
				}
				return elements;
			} catch (error) {
				return [];
			}
		},
		showCategory(categoryName) {
			return this.translate(categoryName);
		},
		restrictedByEvents() {
			return this.highlightVideoData.attributes.event_code_names != null && this.highlightVideoData.attributes.event_code_names.length > 0;
		},
		showEvents(events) {
			return events.map((event) => this.translate(event)).join(', ');
		},
		// userTypesText(userTypes) {
		// 	if (userTypes === '') {
		// 		return this.translate('all_user_types_apply');
		// 	}
		// 	return userTypes.split(',').map((userType) => this.translate(userType.toLowerCase())).join(', ');
		// },
		// devicesText(deviceTypes) {
		// 	if (deviceTypes === '') {
		// 		return this.translate('all_device_types_apply');
		// 	}
		// 	return deviceTypes.split(',').map((deviceType) => this.translate(deviceType.toLowerCase())).join(', ');
		// },
	},
};
</script>
<style scoped>
.icon-width {
	width: 25px;
}
.icon-button {
	width: 35px;
	height: 30px;
	display: flex;
	justify-content: center;
}
</style>
